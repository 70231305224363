






























































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { STORE_MODULE, ROUTE } from '@/enums'
import { IStateAuth } from '@/types'
import { TheNavbarUser, TheCookieConsent } from '@/components'
import { TheSignUpInvitationUuidSetter } from './components'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default Vue.extend({
    name: 'TheFrontFrame',

    components: { TheNavbarUser, TheCookieConsent, TheSignUpInvitationUuidSetter },

    data: () => ({ ROUTE }),

    computed: {
        ...(mapState(['userAccount']) as MapStateToComputed<IStateAuth>),
    },
})
