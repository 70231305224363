
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { MUTATION_SIGN_UP, STORE_MODULE } from '@/enums'
import { IStateAuth, IStateSignUp } from '@/types'
import { UserAccount } from '@/repositiories/auth/models/UserAccount'

const { mapMutations } = createNamespacedHelpers(STORE_MODULE.SIGN_UP)

export default Vue.extend({
    name: 'TheSignUpInvitationUuidSetter',

    created() {
        const invitationUuid = this.$route.query.invitationUuid as string

        if (invitationUuid) this.setInvitationUuid(invitationUuid)
    },

    methods: {
        ...mapMutations({ setInvitationUuid: MUTATION_SIGN_UP.SET_INVITATION_UUID }),
    },

    render: () => null as any,
})
